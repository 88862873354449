import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { HomeFilters } from 'home/constants/filter'
import {
  getHealthDashboardConnections,
  getHealthDashboardConnectionsVolume,
  Period,
} from 'home/services/health-dashboard'
import { CONNECTIONS_TRANSACTIONS, CONNECTIONS_VOLUME } from 'shared/constants'

type VolumeResponse = Awaited<
  ReturnType<typeof getHealthDashboardConnectionsVolume>
>
type TransactionsResponse = Awaited<
  ReturnType<typeof getHealthDashboardConnections>
>

interface HealthDashboardConnectionsProps {
  filters: Partial<HomeFilters>
  enabled: boolean
}

export const useHealthDashboardConnections = ({
  filters,
  enabled,
}: HealthDashboardConnectionsProps): UseQueryResult<
  VolumeResponse | TransactionsResponse,
  AxiosError
> => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const isVolume = filters.filterBy === 'volume'

  return useQuery<VolumeResponse | TransactionsResponse, AxiosError>({
    queryKey: [
      isVolume ? CONNECTIONS_VOLUME : CONNECTIONS_TRANSACTIONS,
      merchantAccountId,
      filters.period,
      filters.currency,
    ],
    queryFn: () =>
      (isVolume
        ? getHealthDashboardConnectionsVolume
        : getHealthDashboardConnections)({
        currency: filters.currency as Uppercase<string>,
        period: filters.period as Period,
      }),
    enabled: enabled && !!filters.currency && !!filters.period,
    meta: {
      error: {
        ignore: [404],
      },
    },
  })
}
